import * as React from "react";
import {
  faArrowRight,
  faArrowCircleDown,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import EventiSlider from "../../components/EventiSlider/EventiSlider";
import Layout from "../../components/Layout";

export default function GratuitiPage({ data }) {
  const {
    totaleEventi: { totalCount },
    allEventiFad: { nodes: eventiFad },
    allEventiRes: { nodes: eventiRes },
    allEventiFsc: { nodes: eventiFsc },
  } = data;

  return (
    <Layout
      pageTitle="Corsi ECM Gratuiti"
      description={`Scegli tra i ${totalCount} corsi ECM gratuiti accreditati da Agenas per tutte le professioni sanitarie`}
    >
      <section className="position-relative">
        <StaticImage
          src="../../images/background-fad-ecm.jpg"
          className="img-header-home"
          alt="Corsi ECM Gratuiti"
          title="Corsi ECM Gratuiti"
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1 className="text-center text-white display-3">
              Corsi ECM Gratuiti
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Scegli tra i {totalCount} corsi ECM gratuiti accreditati da Agenas
              per tutte le professioni sanitarie
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri i corsi
            </a>
          </div>
        </div>
      </section>
      <section id="sezione-corsi" className="container-fluid p-5">
        <h2 className="my-4 text-center titolo-sezione">
          Corsi ECM FAD in evidenza
        </h2>
        <EventiSlider eventi={eventiFad} />
        <div className="row px-5 pt-3 pb-5">
          <div className="col-12 text-center">
            <Link to="/gratuiti/fad/" className="btn btn-outline-primary">
              Vedi tutti i corsi <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </section>
      <section className="container-fluid p-5">
        <h2 className="my-4 text-center titolo-sezione">
          Corsi ECM RES in evidenza
        </h2>
        <EventiSlider eventi={eventiRes} />
        <div className="row px-5 pt-3 pb-5">
          <div className="col-12 text-center">
            <Link to="/gratuiti/residenziali/" className="btn btn-outline-primary">
              Vedi tutti i corsi <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </section>
      <section className="container-fluid p-5">
        <h2 className="my-4 text-center titolo-sezione">
          Corsi ECM FSC in evidenza
        </h2>
        <EventiSlider eventi={eventiFsc} />
        <div className="row px-5 pt-3 pb-5">
          <div className="col-12 text-center">
            <Link to="/gratuiti/fsc/" className="btn btn-outline-primary">
              Vedi tutti i corsi <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    totaleEventi: allEventiJson(
      filter: { quota_partecipazione: { eq: "0,00 €" } }
    ) {
      totalCount
    }
    allEventiFad: allEventiJson(
      limit: 11
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "FAD" }
        quota_partecipazione: { eq: "0,00 €" }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
    allEventiRes: allEventiJson(
      limit: 11
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "RES" }
        quota_partecipazione: { eq: "0,00 €" }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
    allEventiFsc: allEventiJson(
      limit: 11
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "FSC" }
        quota_partecipazione: { eq: "0,00 €" }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
  }
`;
